import React, { useState, useEffect, useRef} from 'react';
// import { BrowserRouter, useParams, useLocation } from 'react-router-dom';
import { BrowserRouter  } from 'react-router-dom';
import Modal from 'react-modal';
import './App.css';
import * as NBAIcons from 'react-nba-logos';
import namesData from './names.json';
import playerData from './player_key.json'
import { type } from '@testing-library/user-event/dist/type';
import MyComponent from './MyComponent';
import teamKey from './teamkey.json';
import { useParams } from 'react-router-dom';


// import colData from './ele --legment.js'
Modal.setAppElement('#root');

function App() {
  useEffect(() => {
      document.title = 'NBAGrid';
  }, []);

// Changing the URL

  const [selected, setSelected] = useState(-1);
  const names = namesData.map((item) => item.full_name);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [isModalOpen3, setModalOpen3] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const suggestionsRef = useRef();
  const [cell0Size, setCell0Size] = useState({ width: 0, height: 0 });
  const [dailyIsSolved, setDailyIsSolved] = useState(localStorage.getItem('isSolved') == 'true');
  const [solvedRandom, setSolvedRandom] = useState(localStorage.getItem('randomSolved') == 'true');
  const savedState = localStorage.getItem('state');
  const [state, setState] = useState(savedState ? savedState : [[],[]]);
  const [imageMatrix, setImageMatrix] = useState([-1,-1,-1, -1,-1,-1, -1, -1, -1]);
  const [dailyMatrix, setDailyMatrix] = useState([
    {
      tag: 'DAL',
      element: <div className='image'><NBAIcons.PHX size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-col'
    }
,
  {
    tag: 'BKN',
    element: <div className='image'><NBAIcons.PHX size={cell0Size.height} className='nba-icons' /></div>,
    container: 'image-col'
  },
  {
    tag: 'OKC',
    element: <div className='image'><NBAIcons.SAS size={cell0Size.height} className='nba-icons' /></div>,
    container: 'image-col'
  },
  {
    tag: 'DET',
    element: <div className='image'><NBAIcons.POR size={cell0Size.height} className='nba-icons' /></div>,
    container: 'image-col'
  },
  {
    tag: 'HOU',
    element: <div className='image'><NBAIcons.BOS size={cell0Size.height} className='nba-icons' /></div>,
    container: 'image-col'
  },
  {
    tag: 'NYK',
    element: <div className='image'><NBAIcons.DAL size={cell0Size.height} className='nba-icons' /></div>,
    container: 'image-col'
  },
]);
  
  const rowData = [
    {
      tag: 'ATL',
      element: <div className='image'><NBAIcons.ATL size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'BOS',
      element: <div className='image'><NBAIcons.BOS size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'BKN',
      element: <div className='image'><NBAIcons.BKN size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'CHA',
      element: <div className='image'><NBAIcons.CHA size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'CHI',
      element: <div className='image'><NBAIcons.CHI size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'CLE',
      element: <div className='image'><NBAIcons.CLE size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'DAL',
      element: <div className='image'><NBAIcons.DAL size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'DEN',
      element: <div className='image'><NBAIcons.DEN size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'DET',
      element: <div className='image'><NBAIcons.DET size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'GSW',
      element: <div className='image'><NBAIcons.GSW size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'HOU',
      element: <div className='image'><NBAIcons.HOU size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'IND',
      element: <div className='image'><NBAIcons.IND size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'LAC',
      element: <div className='image'><NBAIcons.LAC size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'LAL',
      element: <div className='image'><NBAIcons.LAL size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'MEM',
      element: <div className='image'><NBAIcons.MEM size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'MIA',
      element: <div className='image'><NBAIcons.MIA size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'MIL',
      element: <div className='image'><NBAIcons.MIL size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'MIN',
      element: <div className='image'><NBAIcons.MIN size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'NOP',
      element: <div className='image'><NBAIcons.NOP size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'NYK',
      element: <div className='image'><NBAIcons.NYK size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'OKC',
      element: <div className='image'><NBAIcons.OKC size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'ORL',
      element: <div className='image'><NBAIcons.ORL size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'PHI',
      element: <div className='image'><NBAIcons.PHI size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'PHX',
      element: <div className='image'><NBAIcons.PHX size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'POR',
      element: <div className='image'><NBAIcons.POR size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'SAC',
      element: <div className='image'><NBAIcons.SAC size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'SAS',
      element: <div className='image'><NBAIcons.SAS size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'TOR',
      element: <div className='image'><NBAIcons.TOR size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'UTA',
      element: <div className='image'><NBAIcons.UTA size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'WAS',
      element: <div className='image'><NBAIcons.WAS size={cell0Size.height} className='nba-icons' /></div>,
      container: 'image-row'
    },
    {
      tag: 'MVP',
      element: (
        <div className='textimage' size={cell0Size.height }style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '150%', color: '#964B00', fontFamily: 'Impact, sans-serif' }}>
          <div style={{top: '50%'}}>
            MVP
          </div>
        </div>
      ),
      container: 'image-row'
    },
  ];
   

  const [teamList, setTeamList] = useState(dailyMatrix);
    // dailyIsSolved ? [] : dailyMatrix
//TeamList handler
useEffect(() => {
  const tags = teamList.map((item) => item.tag);
  setMatrix([
    [[tags[0], tags[3]], [tags[0], tags[4]], [tags[0], tags[5]]],
    [[tags[1], tags[3]], [tags[1], tags[4]], [tags[1], tags[5]]],
    [[tags[2], tags[3]], [tags[2], tags[4]], [tags[2], tags[5]]]
  ]);
  setRow(teamList.slice(-3));
  setCol(teamList.slice(0,3));
}, [teamList]);

  const [rRow, setRow] = useState([]);
  const [rCol, setCol] = useState([]);



//load images
useEffect(() => {
  const saved = localStorage.getItem('state');
  if (saved) { 
  var jsonArray = JSON.parse(saved);

var images = jsonArray[0];
var teams = jsonArray[1];

setImageMatrix(images);
if (teams[0] == null) {
  setTeamList(dailyMatrix);
} else {
  setTeamList(getElementsByTags(teams));
}


  images.forEach((value, index) => {
    if (value > 0) {
      changeCellBackground(value, index);
    }
  });
  }
  setRow(teamList.slice(-3));
  setCol(teamList.slice(0,3));

  
}, []);

//Set Matrix every 24hrs
  useEffect(() => {
      setDailyIsSolved('false');
      const date = localStorage.getItem('date');
      const today = new Date();
      if (date == today.getDate()) {

      } else {
        setDailyIsSolved('false');
        setDailyMatrix(getRandomElements(rowData, 6));
      }
  }, []);

function handleReset() {
  const emptyimage = [-1,-1,-1, -1,-1,-1, -1, -1, -1];
  setImageMatrix(emptyimage);
  localStorage.setItem(state, [[],[]]);
  const newTeams = getRandomElements(rowData, 6)
  setTeamList(newTeams);
  setSolvedRandom('false');
};



  const [guess, setGuess] = useState('');
  const inputRef = useRef(null);
      const getElementsByTags = (tagsList) => {
      const filteredElements = rowData.filter((element) => tagsList.includes(element.tag));
      
      // Sort the filtered elements based on the order of tagsList
      const sortedElements = tagsList.map((tag) => filteredElements.find((element) => element.tag === tag));
      
      return sortedElements;
    };

  function getRandomElements(array, numElements) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, numElements);
  }

  const updateImages = (rowIndex, colIndex, newValue) => {
    const updatedMatrix = imageMatrix; // Create a copy of the matrix
    const index = rowIndex * 3 + colIndex;
    updatedMatrix[index]= newValue; // Update the value at the specified cell
    setImageMatrix(updatedMatrix); // Update the state with the updated matrix

};
const [matrix, setMatrix] = useState([]);

function isSubset(subset, superset) {
    return subset.every(item => superset.includes(item));
  }
function checkguess(guess, guessX, guessY) {
      var teaml = searchJsonForTeams(playerData, guess);
                if (teaml) {
                  var newl = teaml
                    .replace('[', '')
                    .replace(']', '')
                    .replace(/ /g, '')
                    .replace(/'/g, '')
                    .split(",");
                } else {
                  newl = [];
                }
                if (isSubset(matrix[guessY][guessX], newl)) {
                  updateImages(guessY, guessX, guess);
                  const tags = teamList.map((element) => element.tag);
                  const tmp = JSON.stringify([imageMatrix, tags]);
                  localStorage.setItem('state', tmp);
                  return true; 
                } else {
                    return false;
                }
}
const handleRulesClick = () => {
  setModalOpen2(true);
};
const handleCellClick = (cellId) => { 
  const cellnum = parseInt(cellId.replace('cell',''));
  setSelected(cellnum);
  setModalOpen(true);

  setTimeout(() => {
    const playerGuessElement = document.getElementById('player-guess');
    if (playerGuessElement) {
      playerGuessElement.focus();
    }
  }, 200); // Adjust the delay (in milliseconds) as needed
};
const handleModalClose = () => {
  setModalOpen(false);
  setInputValue('');
  setSuggestions([]);
};
const handleInputChange = (e) => {
  const inputValue = e.target.value; // Remove apostrophes from input value
  setInputValue(inputValue);

  if (inputValue.length >= 2) {
    const filteredSuggestions = names
      .filter(
        (name) =>
          name
            .toLowerCase()// Remove apostrophes from names
            .startsWith(inputValue.toLowerCase().replace(/[’‘]/g, "'"))
      )
      .slice(0, 5);
    setSuggestions(filteredSuggestions);
  } else {
    setSuggestions([]);
  }
};
function searchJsonForName(jsonList, name) {
  const lowerCaseName = name.toLowerCase();
  for (let i = 0; i < jsonList.length; i++) {
    if (jsonList[i].full_name.toLowerCase() === lowerCaseName) {
      return jsonList[i].id;
    }
  }
  return null;
};
function searchJsonForTeams(jsonList, id) {
  for (let i = 0; i < jsonList.length; i++) {
      if (jsonList[i].ID === id) {
      return jsonList[i].Combined;
      }
  }
  return null;
  }
const handleSuggestionClick = (name) => {
  const inputValue = name
  setGuess(inputValue); // Store the guess

  var pid = searchJsonForName(namesData, inputValue);
  var x = selected % 3;
  var y = Math.floor(selected / 3);


  if (checkguess(pid, x, y)) {
    handleModalClose();
    changeCellBackground(pid, selected);
    if(checkMatrixCompletion(imageMatrix)) {
      setModalOpen3(true);
      localStorage.setItem('isSolved', true);
      if(dailyIsSolved) {
        localStorage.setItem('randomSolved',false);
      }
    }
    setModalOpen(false);
  } else {
    const myElement = document.getElementById('first-modal'); 
  }  setSuggestions([]);
};
useEffect(() => {
  if (suggestions.length > 0) {
    inputRef.current.focus();
  }
}, [suggestions]);
useEffect(() => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const inputValue = e.target.value;
      setGuess(inputValue); // Store the guess

      var pid = searchJsonForName(namesData, inputValue);
      var x = selected % 3;
      var y = Math.floor(selected / 3);


      if (checkguess(pid, x, y)) {
        handleModalClose();
        changeCellBackground(pid, selected);
        if(checkMatrixCompletion(imageMatrix)) {
          setModalOpen3(true);
          localStorage.setItem('isSolved', true);
          if(dailyIsSolved) {
            localStorage.setItem('randomSolved',false);
          }
        }
        setModalOpen(false);
      } else {
        const myElement = document.getElementById('first-modal'); 
      }  
      }

  };

  document.addEventListener('keydown', handleKeyPress);
  return () => {
    document.removeEventListener('keydown', handleKeyPress);
  };
}, [selected]); // Add selected as a dependency
useEffect(() => {
  const handleResize = () => {
    const element = document.getElementById('cell0');
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    setCell0Size({ width, height });
  };

  window.addEventListener('resize', handleResize);
  handleResize(); // Initial call to get the size on mount

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
const checkMatrixCompletion = (matrix) => {
  // Iterate through each row and column of the matrix
  for (let row = 0; row < 9; row++) {
      if (matrix[row] < 0) {
        return false;
      }
  }
  return true;
};
function changeCellBackground(pid, cellNumber) {
  if (false) {
    const cellId = `cell${cellNumber}`;
    const cellElement = document.getElementById(cellId);
  if (cellElement) {
    cellElement.style.backgroundImage = `url(https://nbagrid.com/shaq.png)`;
    cellElement.style.backgroundSize = 'cover';
    cellElement.style.backgroundPosition = 'center';
  } 
  } else {
  const imageURL = `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${pid}.png`;
  fetch(imageURL)
  .then(response => {
    if (response.ok) {
    } else {
      const cellId = `cell${cellNumber}`;
        const cellElement = document.getElementById(cellId);
  if (cellElement) {
    cellElement.style.backgroundImage = `url(https://nbagrid.com/default.png)`;
    cellElement.style.backgroundSize = 'cover';
    cellElement.style.backgroundPosition = 'center';
  } // Set imageURL to a generic image URL
    }
  })
  const cellId = `cell${cellNumber}`;
  const cellElement = document.getElementById(cellId);
  if (cellElement) {
    cellElement.style.backgroundImage = `url(${imageURL})`;
    cellElement.style.backgroundSize = 'cover';
    cellElement.style.backgroundPosition = 'center';
  }
 }
}
return (
  <BrowserRouter>
  <div className="container">
    <h1 className='title'>NBAGrid</h1>
  <div className="image-col">
    {rCol
      .map((icon, index) => (
        <div key={index} className="image">
          {rowData.find(item => item.tag === rCol[index].tag)?.element}
        </div>
      ))}
  </div>
  <div className="image-row">
    {rRow
      .map((icon, index) => (
        <div key={index} className="image">
          {rowData.find(item => item.tag === rRow[index].tag)?.element}
        </div>
      ))}
  </div>
  <div id="gridContainer">
      <div className="grid">
        <div className="cell" id="cell0" onClick={() => handleCellClick('cell0')}></div>
        <div className="cell" id="cell1" onClick={() => handleCellClick('cell1')}></div>
        <div className="cell" id="cell2" onClick={() => handleCellClick('cell2')}></div>
        <div className="cell" id="cell3" onClick={() => handleCellClick('cell3')}></div>
        <div className="cell" id="cell4" onClick={() => handleCellClick('cell4')}></div>
        <div className="cell" id="cell5" onClick={() => handleCellClick('cell5')}></div>
        <div className="cell" id="cell6" onClick={() => handleCellClick('cell6')}></div>
        <div className="cell" id="cell7" onClick={() => handleCellClick('cell7')}></div>
        <div className="cell" id="cell8" onClick={() => handleCellClick('cell8')}></div>
      </div>
    </div>
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleModalClose}
      className="modal"
      overlayClassName="overlay"
      id="first-modal"
    >
      <input
        type="text"
        ref={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        className='guess'
        placeholder="Search"
        id='player-guess'
      />
      <div className="suggestions" ref={suggestionsRef}>
        {suggestions.length > 0 && (
          <ul>
            {suggestions.map((name, index) => (
              <li key={index} onClick={() => handleSuggestionClick(name)}>
                {name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Modal>

    <div className="container">
      {/* ...other code... */}
      <div className="rules" onClick={handleRulesClick}>
        Rules
      </div>
      {/* ...other code... */}
    </div>
    <Modal
      isOpen={isModalOpen2}
      onRequestClose={() => setModalOpen2(false)}
      className="modal"
      overlayClassName="overlay"
      id="second-modal" // Added id for styling
    >
     <h1 className="rules-title">Rules & FAQ</h1>
     <div className="rules-box">
      <p className="bullet">The grid is a matrix of NBA teams.</p>
      <p className="bullet">The goal is to fill in each square with a player who has played for both teams.</p>
      <p className="bullet">You have unlimited attempts to guess</p>
      {/* <p class="bullet">If you find an error, please report it to nbagrid @ twitter.</p> */}
     </div>
    </Modal>
    <Modal
      isOpen={isModalOpen3}
      onRequestClose={() => setModalOpen3(false)}
      className="modal"
      overlayClassName="overlay"
      id="second-modal" // Added id for styling
    >
     <h1 className="rules-title">Congratulations!</h1>
     <div className="rules-box">
      <p className="bullet">You won the game! .</p>
      <p className="bullet">Here was your unique combination of teams:</p>
      <p className="bullet">{teamList.map((item) => item.tag)[0]}</p>
      <p className="bullet">{teamList.map((item) => item.tag)[1]}</p>
      <p className="bullet">{teamList.map((item) => item.tag)[2]}</p>
      <p className="bullet">{teamList.map((item) => item.tag)[3]}</p>
      <p className="bullet">{teamList.map((item) => item.tag)[4]}</p>
      <p className="bullet">{teamList.map((item) => item.tag)[5]}</p>
      <button onClick={() => {navigator.clipboard.writeText('Can you solve the nbagrid? \n nbagrid.com')}}>Click here to share with friends</button>

      {/* <p class="bullet">If you find an error, please report it to nbagrid @ twitter.</p> */}
     </div>
    </Modal>
  </div>
  </BrowserRouter>
);
}
export default App;
